export const Toast = ({ msg }) => <>{msg}</>;
export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};
export const generateUrl = () => {
    const envMode = process.env.REACT_APP_ENV;
    let backendUrlPreFix;
    let finalUrl;
    if (envMode === 'prod') {
        backendUrlPreFix = 'api.';
    } else if (envMode === 'test' || envMode === 'preprod') {
        backendUrlPreFix = 'test.api.';
    } else {
        backendUrlPreFix = 'dev.api.';
    }
    finalUrl = `https://${backendUrlPreFix}incruiter.com/calendar-system/api/v1/calendar`;
    return finalUrl;
};
