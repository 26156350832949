import {
    GET_INTERVIEWER_CALENDAR,
    POST_INTERVIEWER_CALENDAR,
    VERIFY_CALENDAR_REQUEST,
    POST_CANDIDATE_CALENDAR,
    GET_INTERVIEWER_CALENDAR_SUCCESS,
    POST_INTERVIEWER_CALENDAR_SUCCESS,
    VERIFY_CALENDAR_REQUEST_SUCCESS,
    POST_CANDIDATE_CALENDAR_SUCCESS,
    GET_INTERVIEWER_CALENDAR_FAILURE,
    POST_INTERVIEWER_CALENDAR_FAILURE,
    VERIFY_CALENDAR_REQUEST_FAILURE,
    POST_CANDIDATE_CALENDAR_FAILURE,
    GET_RESCHEDULE_DATA,
    GET_RESCHEDULE_DATA_SUCCESS,
    GET_RESCHEDULE_DATA_FAILURE,
    POST_RESCHEDULE_DATA,
    POST_RESCHEDULE_DATA_SUCCESS,
    POST_RESCHEDULE_DATA_FAILURE,
} from './types';

export const getInterviewerCalendar = (payload) => ({
    type: GET_INTERVIEWER_CALENDAR,
    payload,
});
export const getInterviewerCalendarSuccess = (payload) => ({
    type: GET_INTERVIEWER_CALENDAR_SUCCESS,
    payload,
});
export const getInterviewerCalendarFailure = () => ({
    type: GET_INTERVIEWER_CALENDAR_FAILURE,
});

export const postInterviewerCalendar = (payload) => ({
    type: POST_INTERVIEWER_CALENDAR,
    payload,
});
export const postInterviewerCalendarSuccess = (payload) => ({
    type: POST_INTERVIEWER_CALENDAR_SUCCESS,
    payload,
});
export const postInterviewerCalendarFailure = () => ({
    type: POST_INTERVIEWER_CALENDAR_FAILURE,
});

export const verifyCalendarRequest = (payload) => ({
    type: VERIFY_CALENDAR_REQUEST,
    payload,
});
export const verifyCalendarRequestSuccess = (payload) => ({
    type: VERIFY_CALENDAR_REQUEST_SUCCESS,
    payload,
});
export const verifyCalendarRequestFailure = () => ({
    type: VERIFY_CALENDAR_REQUEST_FAILURE,
});

export const postCandidateCalendar = (payload) => ({
    type: POST_CANDIDATE_CALENDAR,
    payload,
});
export const postCandidateCalendarSuccess = (payload) => ({
    type: POST_CANDIDATE_CALENDAR_SUCCESS,
    payload,
});
export const postCandidateCalendarFailure = () => ({
    type: POST_CANDIDATE_CALENDAR_FAILURE,
});
export const getRescheduleData = (payload) => ({
    type: GET_RESCHEDULE_DATA,
    payload,
});
export const getRescheduleDataSuccess = (payload) => ({
    type: GET_RESCHEDULE_DATA_SUCCESS,
    payload,
});
export const getRescheduleDataFailure = () => ({
    type: GET_RESCHEDULE_DATA_FAILURE,
});
export const postRescheduleData = (payload) => ({
    type: POST_RESCHEDULE_DATA,
    payload,
});
export const postRescheduleDataSuccess = () => ({
    type: POST_RESCHEDULE_DATA_SUCCESS,
});
export const postRescheduleDataFailure = () => ({
    type: POST_RESCHEDULE_DATA_FAILURE,
});
