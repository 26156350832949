import React from 'react';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Toast } from '../../utils/helpers';
import {
    GET_INTERVIEWER_CALENDAR,
    POST_INTERVIEWER_CALENDAR,
    VERIFY_CALENDAR_REQUEST,
    POST_CANDIDATE_CALENDAR,
    GET_RESCHEDULE_DATA,
    POST_RESCHEDULE_DATA,
} from '../action/types';
import {
    getInterviewerCalendarSuccess,
    getInterviewerCalendarFailure,
    postInterviewerCalendarSuccess,
    postInterviewerCalendarFailure,
    verifyCalendarRequestSuccess,
    verifyCalendarRequestFailure,
    postCandidateCalendarSuccess,
    postCandidateCalendarFailure,
    getRescheduleDataSuccess,
    getRescheduleDataFailure,
    postRescheduleDataSuccess,
    postRescheduleDataFailure,
} from '../action';
import API from '../../utils/api';

function* getInterviewerCalendarRequest(action) {
    try {
        const res = yield API.get(`interviewerSlots/${action.payload.intId}`);
        if (res.status === 200) {
            yield put(getInterviewerCalendarSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getInterviewerCalendarFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}
function* postInterviewerCalendarRequest(action) {
    try {
        const res = yield API.post('updateCalendar/interviewer', action.payload.data);
        if (res.status === 200 || res.status === 201) {
            yield put(postInterviewerCalendarSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(postInterviewerCalendarFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}
function* verifyCalendarRequests(action) {
    try {
        const res = yield API.get(`verifyCalendarRequest/${action.payload.calendarId}`);
        if (res.status === 200) {
            yield put(verifyCalendarRequestSuccess(res.data.result));
        }
    } catch (e) {
        yield put(verifyCalendarRequestFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}
function* postCandidateCalendarRequest(action) {
    try {
        const res = yield API.post('updateCalendar/candidate', action.payload.data);
        if (res.status === 200 || res.status === 201) {
            yield put(postCandidateCalendarSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(postCandidateCalendarFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* getRescheduleDataRequest(action) {
    try {
        const res = yield API.get(`rescheduleInterview/${action.payload.interviewId}/${action.payload.roomId}`);
        if (res.status === 200) {
            yield put(getRescheduleDataSuccess(res.data.result));
        }
    } catch (e) {
        yield put(getRescheduleDataFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

function* postRescheduleDataRequest(action) {
    try {
        const res = yield API.post('submitDateAndTime', action.payload.data);
        if (res.status === 200 || res.status === 201) {
            yield toast.success(<Toast msg={'Thank you for scheduling your availability!'} />);
            yield put(postRescheduleDataSuccess(res.data.result));
            yield call(action.payload.callback);
        }
    } catch (e) {
        yield put(postRescheduleDataFailure());
        yield e?.response?.data?.message && toast.error(<Toast msg={e?.response?.data?.message} />);
    }
}

export function* watchGetInterviewerCalendarAPI() {
    yield takeEvery(GET_INTERVIEWER_CALENDAR, getInterviewerCalendarRequest);
}
export function* watchPostInterviewerCalendarAPI() {
    yield takeEvery(POST_INTERVIEWER_CALENDAR, postInterviewerCalendarRequest);
}
export function* watchVerifyCalendarRequestsAPI() {
    yield takeEvery(VERIFY_CALENDAR_REQUEST, verifyCalendarRequests);
}
export function* watchPostCandidateCalendarAPI() {
    yield takeEvery(POST_CANDIDATE_CALENDAR, postCandidateCalendarRequest);
}
export function* watchGetRescheduleDataAPI() {
    yield takeEvery(GET_RESCHEDULE_DATA, getRescheduleDataRequest);
}
export function* watchPostRescheduleDataAPI() {
    yield takeEvery(POST_RESCHEDULE_DATA, postRescheduleDataRequest);
}

export default function* rootSaga() {
    yield all([
        watchGetInterviewerCalendarAPI(),
        watchPostInterviewerCalendarAPI(),
        watchVerifyCalendarRequestsAPI(),
        watchPostCandidateCalendarAPI(),
        watchGetRescheduleDataAPI(),
        watchPostRescheduleDataAPI(),
    ]);
}
