export const VERIFY_CALENDAR_REQUEST = 'VERIFY_CALENDAR_REQUEST';
export const VERIFY_CALENDAR_REQUEST_SUCCESS = 'VERIFY_CALENDAR_REQUEST_SUCCESS';
export const VERIFY_CALENDAR_REQUEST_FAILURE = 'VERIFY_CALENDAR_REQUEST_FAILURE';

export const GET_INTERVIEWER_CALENDAR = 'GET_INTERVIEWER_CALENDAR';
export const GET_INTERVIEWER_CALENDAR_SUCCESS = 'GET_INTERVIEWER_CALENDAR_SUCCESS';
export const GET_INTERVIEWER_CALENDAR_FAILURE = 'GET_INTERVIEWER_CALENDAR_FAILURE';

export const POST_INTERVIEWER_CALENDAR = 'POST_INTERVIEWER_CALENDAR';
export const POST_INTERVIEWER_CALENDAR_SUCCESS = 'POST_INTERVIEWER_CALENDAR_SUCCESS';
export const POST_INTERVIEWER_CALENDAR_FAILURE = 'POST_INTERVIEWER_CALENDAR_FAILURE';

export const POST_CANDIDATE_CALENDAR = 'POST_CANDIDATE_CALENDAR';
export const POST_CANDIDATE_CALENDAR_SUCCESS = 'POST_CANDIDATE_CALENDAR_SUCCESS';
export const POST_CANDIDATE_CALENDAR_FAILURE = 'POST_CANDIDATE_CALENDAR_FAILURE';

export const GET_RESCHEDULE_DATA = 'GET_RESCHEDULE_DATA';
export const GET_RESCHEDULE_DATA_SUCCESS = 'GET_RESCHEDULE_DATA_SUCCESS';
export const GET_RESCHEDULE_DATA_FAILURE = 'GET_RESCHEDULE_DATA_FAILURE';

export const POST_RESCHEDULE_DATA = 'POST_RESCHEDULE_DATA';
export const POST_RESCHEDULE_DATA_SUCCESS = 'POST_RESCHEDULE_DATA_SUCCESS';
export const POST_RESCHEDULE_DATA_FAILURE = 'POST_RESCHEDULE_DATA_FAILURE';
