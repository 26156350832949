import {
    GET_INTERVIEWER_CALENDAR,
    POST_INTERVIEWER_CALENDAR,
    VERIFY_CALENDAR_REQUEST,
    POST_CANDIDATE_CALENDAR,
    GET_INTERVIEWER_CALENDAR_SUCCESS,
    POST_INTERVIEWER_CALENDAR_SUCCESS,
    VERIFY_CALENDAR_REQUEST_SUCCESS,
    POST_CANDIDATE_CALENDAR_SUCCESS,
    GET_INTERVIEWER_CALENDAR_FAILURE,
    POST_INTERVIEWER_CALENDAR_FAILURE,
    VERIFY_CALENDAR_REQUEST_FAILURE,
    POST_CANDIDATE_CALENDAR_FAILURE,
    GET_RESCHEDULE_DATA,
    GET_RESCHEDULE_DATA_SUCCESS,
    GET_RESCHEDULE_DATA_FAILURE,
    POST_RESCHEDULE_DATA,
    POST_RESCHEDULE_DATA_SUCCESS,
    POST_RESCHEDULE_DATA_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    interviewerCalendarData: null,
    verifyCalendarRequest: null,
    rescheduleData: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_INTERVIEWER_CALENDAR:
            return { ...state, loading: true };
        case GET_INTERVIEWER_CALENDAR_SUCCESS:
            return {
                ...state,
                loading: false,
                interviewerCalendarData: action.payload,
            };
        case GET_INTERVIEWER_CALENDAR_FAILURE:
            return { ...state, loading: false };

        case POST_INTERVIEWER_CALENDAR:
            return { ...state, loading: true };
        case POST_INTERVIEWER_CALENDAR_SUCCESS:
            return { ...state, loading: false };
        case POST_INTERVIEWER_CALENDAR_FAILURE:
            return { ...state, loading: false };

        case VERIFY_CALENDAR_REQUEST:
            return { ...state, loading: true };
        case VERIFY_CALENDAR_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                verifyCalendarRequest: action.payload,
            };
        case VERIFY_CALENDAR_REQUEST_FAILURE:
            return { ...state, loading: false };

        case POST_CANDIDATE_CALENDAR:
            return { ...state, loading: true };
        case POST_CANDIDATE_CALENDAR_SUCCESS:
            return { ...state, loading: false };
        case POST_CANDIDATE_CALENDAR_FAILURE:
            return { ...state, loading: false };

        case GET_RESCHEDULE_DATA:
            return { ...state, loading: true };
        case GET_RESCHEDULE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                rescheduleData: action.payload,
            };
        case GET_RESCHEDULE_DATA_FAILURE:
            return { ...state, loading: false };

        case POST_RESCHEDULE_DATA:
            return { ...state, loading: true };
        case POST_RESCHEDULE_DATA_SUCCESS:
            return { ...state, loading: false };
        case POST_RESCHEDULE_DATA_FAILURE:
            return { ...state, loading: false };

        default:
            return state;
    }
};
