import React, { lazy, memo, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer, Slide } from 'react-toastify';
import store from './redux/store';
import './libcss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './component/Loader';
import ErrorBoundary from './component/ErrorBoundary';
const CloseButton = ({ closeToast }) => (
    <i className="fa fa-times-circle align-self-center f-18 text-white" onClick={closeToast} />
);
const InterviewerCalendar = lazy(() => import('./container/InterviewerCalendar'));
const CandidateCalendar = lazy(() => import('./container/CandidateCalendar'));
const RescheduleCalendar = lazy(() => import('./container/RescheduleCalendar'));
const NotFound = lazy(() => import('./container/404NotFound'));
const RouteList = memo(() => {
    return (
        <Router>
            <ErrorBoundary>
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route exact={true} path={'reschedule'} element={<RescheduleCalendar />}>
                            <Route path={':interviewId'} element={<RescheduleCalendar />}>
                                <Route path={':roomId'} element={<RescheduleCalendar />} />
                            </Route>
                        </Route>
                        <Route exact={true} path={'interviewer'} element={<InterviewerCalendar />}>
                            <Route path={':calendarId'} element={<InterviewerCalendar />} />
                        </Route>
                        <Route exact={true} path={'candidate'} element={<CandidateCalendar />}>
                            <Route path={':calendarId'} element={<CandidateCalendar />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Suspense>
            </ErrorBoundary>
        </Router>
    );
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouteList />
            <ToastContainer
                autoClose={2000}
                draggable={false}
                transition={Slide}
                closeButton={CloseButton}
                hideProgressBar={true}
                toastClassName="toast-notification"
            />
        </Provider>
    </React.StrictMode>
);
